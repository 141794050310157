<template>
  <div>
    <a-spin :spinning="spinning">
      <div>
        <a-page-header style="background-color: #fff;" :title="(user_id ? '编辑' : '创建') + '员工'" @back="$router.back()">
          <template #extra>
            <a-button key="1" @click="$router.back()">取消</a-button>
            <a-button key="2" @click="submitAdd" type="primary">提交</a-button>
          </template>
        </a-page-header>

      </div>
      <div class="my-card">

        <a-form-model ref="userForm" :model="userInfo" :rules="userRules" :label-col="{ span: 4 }" labelAlign="left"
          :wrapper-col="{ span: 21 }">
          <div>
            <a-card class="my-card-content">
              <a-descriptions title="基本信息"></a-descriptions>
              <a-row :gutter="8">
                <a-col :span="8">
                  <a-form-model-item class="my-flex" prop="name" label="用户名">
                    <a-input placeholder="请输入" v-model="userInfo.name" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item class="my-flex" prop="phone" label="手机号">
                    <a-input placeholder="请输入" v-model="userInfo.phone" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item class="my-flex" prop="password" label="密码" v-if="!user_id">
                    <a-input placeholder="请输入" type="password" v-model="userInfo.password" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item class="my-flex" prop="type" label="用户类型">
                    <a-select placeholder="请选择" v-model="userInfo.type">
                      <a-select-option :value="1">
                        内部用户
                      </a-select-option>
                      <a-select-option :value="3">
                        代理用户
                      </a-select-option>
                      <a-select-option :value="2">
                        医院
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item class="my-flex" prop="hospital_ids" label="关联医院">
                    <a-select mode="multiple" placeholder="请选择医院" v-model="userInfo.hospital_ids">
                      <!-- <a-select-option :value="item.hospital_id" v-for="item in hospitals"
                    :key="item.hospital_id"></a-select-option> -->
                      <a-select-option v-for="item in hospitals" :key="item.hospital_id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <!-- <a-form-model-item prop="remark" label="备注">
            <a-textarea placeholder="请输入" type="remark" :rows="4" v-model="userInfo.remark" />
          </a-form-model-item> -->
            </a-card>
            <a-card class="my-card-content">
              <a-descriptions title="权限管理"></a-descriptions>
              <a-form-model-item :label-col="{ span: 1 }" required label="小程序" labelAlign="left">
                <a-checkbox @change="onWXAppletChange" :checked="WXApplet">设备调试</a-checkbox>
              </a-form-model-item>
              <a-form-model-item :label-col="{ span: 1 }" prop="role_list" label="角色" labelAlign="left">
                <a-checkbox-group v-model="userInfo.role_list">
                  <a-checkbox v-for="(item, index) in roleList" :key="item.role_id" :data-index="index"
                    :value="item.role_id">{{
                      item.name }}</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
            </a-card>
          </div>

          <a-card class="my-card-content">
            <a-descriptions title="绑定对账"></a-descriptions>
            <div style="margin-bottom: 20px">
              <a-button @click="showAccountModal" type="primary" icon="plus">添加对账</a-button>
            </div>
            <a-table style="width: 100%;" :columns="countColumns" :data-source="checkedList" :bordered="true" :rowKey="(item, index) => {
              return index
            }
              ">
              <template slot="type" slot-scope="text, record">
                {{
                  record.super_account_id == -1
                  ? '一级代理'
                  : '下级代理'
                }}
              </template>
              <template slot="is_tixian" slot-scope="text, record, index">
                <a-switch v-model="record.is_tixian" @change="tixianChange(index)" />
              </template>
              <template slot="is_wxpower" slot-scope="text, record, index">
                <a-switch v-model="record.is_wxpower" @change="powerChange(index)" />
              </template>
              <template slot="is_wxdev" slot-scope="text, record, index">
                <a-switch v-model="record.is_wxdev" @change="powerChangeDev(record, index)" />
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <a-button icon="delete" @click="deleteIndex(index)"></a-button>
              </template>
            </a-table>
          </a-card>
        </a-form-model>
        <a-modal title="选择对账账户" :visible="accountVisible" @cancel="cancelModal" @ok="confirmSelect">
          <a-table :rowKey="(item, index) => {
            return index
          }
            " :row-selection="{
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
  }" :columns="columns" :data-source="accountList" :bordered="true" :pagination="{
  hideOnSinglePage: true,
  current: page.current,
  total: page.total,
}" @change="listChange">
            <template slot="type" slot-scope="text, record">
              {{
                record.super_account_id == -1
                ? '一级代理'
                : '下级代理'
              }}
            </template>
          </a-table>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { leaveAccountSelect } from '@/api/reconciliation.js'
import { roleList, userAdd, userEdit, userInfoFn } from '@/api/yuangong.js'
import { domain } from '@/siteInfo.js'
export default {
  data() {
    let columns = [
      {
        title: '类型',
        // key: 'type',
        scopedSlots: { customRender: 'type' },
      },
      {
        title: '上级',
        // key: 'type',
        key: 'super_name',
        dataIndex: 'super_name',
      },
      {
        title: '姓名',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '电话',
        key: 'phone',
        dataIndex: 'phone',
      },
    ]
    let countColumns = [
      {
        title: '类型',
        // key: 'type',
        scopedSlots: { customRender: 'type' },
      },
      {
        title: '上级',
        // key: 'type',
        key: 'super_name',
        dataIndex: 'super_name',
      },
      {
        title: '姓名',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: '电话',
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        title: '支持提现',
        key: 'is_tixian',
        dataIndex: 'is_tixian',
        scopedSlots: { customRender: 'is_tixian' },
      },
      // {
      //   title: '开放小程序',
      //   key: 'is_wxpower',
      //   dataIndex: 'is_wxpower',
      //   scopedSlots: { customRender: 'is_wxpower' },
      // },
      {
        title: '开放小程序',
        children: [
          {
            title: '分账',
            dataIndex: 'is_wxpower',
            key: 'is_wxpower',
            scopedSlots: { customRender: 'is_wxpower' },
          },
          {
            title: '调试',
            dataIndex: 'is_wxdev',
            key: 'is_wxdev',
            scopedSlots: { customRender: 'is_wxdev' },
          },
        ],
      },
      {
        title: '操作',
        key: 'operation',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ]
    return {
      accountList: [],
      columns,
      countColumns,
      hospitals: [], //医院列表
      page: {
        current: 1,
        total: 0,
      },
      spinning: false,
      accountVisible: false,
      userInfo: {
        name: '',
        phone: '',
        password: '',
        remark: '',
        role_list: [],
        account_binds: [],
        applet_power: '',
        hospital_ids: [],
        WXApplet: true,
      },
      WXApplet: false,
      userRules: {
        name: [{ required: true, message: '请填写员工名称', trigger: 'blur' }],
        phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
        password: [{ required: true, message: '请填写密码', trigger: 'blur' }],
        role_list: [{ required: true, message: '请选择角色', trigger: 'change' }],
        type: [{ required: true, message: '请选择用户类型', trigger: 'change' }],
        hospital_ids: [{ required: true, message: '请选择关联医院', trigger: 'change' }],
      },
      user_id: '',
      selectedRowKeys: [],
      oldRowKeys: [], //旧的
      hadChecked: [],
      checkedList: [],
      roleList: [],
    }
  },
  async created() {
    this.getAccountList()
    this.getHospitals()
    this.spinning = true
    const res = await roleList({ limit: 10000 })
    let user_id = this.$route.query.user_id
    if (user_id) {
      this.user_id = user_id
      const userRes = await userInfoFn({ user_id })
      console.log('员工详情', userRes.data.account_binds)
      if (userRes.code === 0) {
        let {
          name,
          phone,
          password,
          remark,
          role_list,
          account_binds,
          applet_power,
          hospital_ids,
          type
        } = userRes.data
        this.userInfo = {
          name,
          phone,
          password,
          remark,
          role_list,
          account_binds,
          applet_power,
          hospital_ids,
          type
        }
        console.log(123, account_binds)
        if (applet_power == 1) {
          this.WXApplet = true
        } else {
          this.WXApplet = false
        }
        this.checkedList = account_binds.map((item) => {
          item.is_tixian = item.is_tixian === 1
          item.is_wxpower = item.is_wxpower === 1
          item.is_wxdev = item.is_wxdev === 1
          return item
        })
        console.log(this.checkedList)
      }
    }
    this.spinning = false
    this.roleList = res.data.list
  },
  methods: {
    getHospitals() {
      this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(
        (res) => {
          if (res.code === 0) {
            this.hospitals = res.data
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    getDefaultValues() {
      // 假设 this.selectedHospitalIds 是一个包含选中医院 ID 的数组
      return this.hospitals.filter(hospital => this.selectedHospitalIds.includes(hospital.hospital_id)).map(hospital => hospital.name);
    },
    showAccountModal() {
      this.accountVisible = true
      this.setCheckedList()
    },
    cancelModal() {
      this.accountVisible = false
    },
    listChange(e) {
      this.page.current = e.current
      this.getAccountList()
    },
    setCheckedList() {
      this.selectedRowKeys = []
      // 获取所有选中过的账户ID
      let checkedIds = this.checkedList.map((item) => {
        return item.account_id
      })
      // 对比每个账户,如果存在就选中
      for (let i = 0; i < this.accountList.length; i++) {
        let item = this.accountList[i]
        if (checkedIds.includes(item.account_id)) {
          this.selectedRowKeys.push(i)
          this.oldRowKeys = JSON.parse(JSON.stringify(this.selectedRowKeys))
        }
      }
    },
    selfIncludes(arr, obj) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == obj) {
          return true
        }
      }
      return false
    },
    async getAccountList() {
      const res = await leaveAccountSelect({
        page: this.page.current,
        limit: 10,
        name: this.name
      })
      if (res.code === 0) {
        this.accountList = res.data.list
        this.page.total = res.data.total
        this.setCheckedList()
      }
    },
    onSelectChange(e, a) {
      this.selectedRowKeys = e
    },
    cutMore(arr) {
      const res = new Map()
      return arr.filter(
        (item) => !res.has(item.account_id) && res.set(item.account_id, 1)
      )
    },
    tixianChange(ind) {
      console.log(ind)
    },
    powerChange(ind) {
      console.log(ind)
      console.log(this.checkedList)
    },
    powerChangeDev(record, ind) {
      console.log(ind)
    },
    confirmSelect() {
      // 旧的:[1,4,5]
      // 新的:[2,3,5]
      // 从checkedList中删掉1所对应的元素
      // 旧的选中的,理应对比旧的,如果旧的选中,而新的没有选中,则从checkedList中应该删掉旧的
      let oldRowKeys = this.oldRowKeys
      let selectedRowKeys = this.selectedRowKeys
      for (let i = 0; i < oldRowKeys.length; i++) {
        // 如果在新的选中的数组中没有旧的
        if (!selectedRowKeys.includes(oldRowKeys[i])) {
          let cutInd = this.checkedList.findIndex(
            (item) =>
              item.account_id == this.accountList[oldRowKeys[i]].account_id
          )
          this.checkedList.splice(cutInd, 1)
        }
      }
      // 上面已经把删除的从checkedList中删除了
      // 先把所有选中的放进去,再去重
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        this.checkedList.push({
          ...this.accountList[this.selectedRowKeys[i]],
          is_tixian: false,
          is_wxpower: false,
          is_wxdev: false,
        })
      }
      this.checkedList = this.cutMore(this.checkedList)
      this.accountVisible = false
      this.selectedRowKeys = []
      this.oldRowKeys = []
    },
    deleteIndex(ind) {
      this.checkedList.splice(ind, 1)
    },
    // 是否勾选小程序设备调试
    onWXAppletChange(e) {
      this.WXApplet = e.target.checked
    },
    submitAdd() {
      if (this.WXApplet) {
        this.userInfo.applet_power = 1
      } else {
        this.userInfo.applet_power = -1
      }
      this.userInfo.account_binds = this.checkedList.map((item) => {
        return {
          account_id: item.account_id,
          is_tixian: item.is_tixian ? 1 : 0,
          is_wxpower: item.is_wxpower ? 1 : 0,
          is_wxdev: item.is_wxdev ? 1 : 0,
        }
      })
      this.$refs.userForm.validate(async (valid) => {
        if (valid) {
          let res = null
          this.spinning = true
          if (this.user_id) {
            res = await userEdit({
              user_id: this.user_id,
              ...this.userInfo,
            })
          } else {
            res = await userAdd({ ...this.userInfo })
          }
          if (res.code === 0) {
            this.spinning = false
            this.$message.success(this.user_id ? '编辑成功' : '添加成功', 1.5)
            this.$router.push({ path: '/yuangongList' })
          } else {
            this.spinning = false
            this.$message.error(
              res.msg || (this.user_id ? '编辑失败' : '添加失败'),
              1.5
            )
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.app-body {
  padding: 0 !important;
  background-color: #f5f5f5 !important;
}
</style>
<style lang="less" scoped>
.btn-control {
  text-align: center;
}

.my-card {
  padding: 30px;
  top: 0;
  left: 0;
  background-color: #F5F5F5;
}

.my-card-content {
  margin-bottom: 20px;
}

.my-flex {
  display: flex;
  flex-direction: column;
}
</style>
