import { domain } from '@/siteInfo'
console.log('请求接口里的', domain)

import { fetch } from '@/utils/fetchs.js'

// 对账用户列表
export const reconciliationAccountList = (params = {}) => fetch(domain + '/admin/v1/reconciliation/accountList', params)

// 对账用户选择器
export const reconciliationAccountSelect = (params = {}) => fetch(domain + '/admin/v1/reconciliation/accountSelect', params)

// 对账用户选择器
export const leaveAccountSelect = (params = {}) => fetch(domain + '/admin/v1/reconciliation/leaveSelect', params)
